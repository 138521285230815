.bg-img {
    background-image: url("https://images.pexels.com/photos/33129/popcorn-movie-party-entertainment.jpg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-blend-mode: overlay;
}

.hero-pos {
    position: relative;
    top: 25vh;
  }

  .hero-text {
    margin: 0;
    padding: 0;
  text-transform: uppercase;
  }
  

  .hero {
    background-color: #222;
    width: 100%;
    height: 100vh;
    font-size: xx-large;
  }